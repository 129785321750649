export default class Paypolicy {
  id!: number
  member_number!: number
  client_identification!: string
  id_safe!: string
  coin_type!: string
  amount!: number
  half!: string
  date!: Date
  period_from!: Date
  period_until!: Date
  observation!: string
}
